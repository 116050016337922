<template>
  <div id="DuellInvitations" class="uk-card-default rounded uk-card-hover mt-3 p-2">
    <div class="course-card-body">
      <div class="section-header-left">
        <h4 class="uk-text-truncate"><i class="uil-bolt-alt"></i> Düello Davetleri</h4>
      </div>
      <div class="pt-3 pb-1 text-center">

        <button class="duello-test uk-button uk-button-default invisible" type="button" onclick="UIkit.notification({message: '<div class=\'uk-text-small uk-grid-small bg-gradient-success uk-light round shadow-primary uk-padding-small\' style=\'margin: -15px ; padding-left:0px \' uk-grid><div class=\'uk-width-auto\'><img width=\'50\' src=\'../assets/images/avatars/avatar-1.jpg\' class=\'uk-avatar\' style=\'border-radius:50%\'></div><div class=\'uk-width-expand\'><h5 class=\'uk-margin-remove-adjacent uk-margin-small-bottom\'> Düello davetin var! </h5><p style=\'color:#fff;line-height:1.2\'> <b>Selma Güler</b> seninle düello yapmak istiyor!</p></div></div>', pos: 'bottom-right'})">
          Click me </button>

        <br>
        <br>
        <br>
        <br>
        <br>
        <ul class="uk-list-divider mt-2 pr-3 challange d-none">
          <li class="d-flex justify-content-between align-items-center"><a href="#">Erhan
            Güler</a>
            <button type="button"
                    class="btn btn-animated btn-primary btn-sm btn-animated-x uk-first-column">
              <span class="btn-inner--visible">Meydan Oku</span>
              <span class="btn-inner--hidden">
                                                    <i class="uil-bolt-alt"></i>
                                                </span>
            </button>
          </li>
          <li class="d-flex justify-content-between align-items-center"><a href="#">Erhan
            Güler</a>
            <button type="button"
                    class="btn btn-animated btn-primary btn-sm btn-animated-x uk-first-column">
              <span class="btn-inner--visible">Meydan Oku</span>
              <span class="btn-inner--hidden">
                                                    <i class="uil-bolt-alt"></i>
                                                </span>
            </button>
          </li>
        </ul>
      </div>

      <div class="course-card-footer">
        <a href="duello.html" class="btn btn-secondary btn-sm uk-first-column">
          Daha Fazla <i class="uil-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DuellInvitations"
}
</script>

<style scoped>

</style>