<template>
  <li id="LiderUser" class="d-flex justify-content-between align-items-center"><span><i
      class="uil-user"></i> {{name}}</span> <span>{{number}} <i
      class="uil-coins"></i></span></li>

</template>

<script>
export default {
  name: "LiderUser",
  props:{
    name: {
      required: true,
    },
    number: {
      required: true,
    },

  }
}
</script>

