<template>
  <div id="LiderTable" class="uk-card-default rounded uk-card-hover mt-3 p-2">
    <div class="course-card-body">
      <div class="section-header-left">
        <h4 class="uk-text-truncate"><i class="uil-trophy"></i> Liderler Tablosu</h4>
      </div>
      <div class="pt-3 pb-1 text-center">
        <ul class="uk-list-divider mt-2 pr-3">
          <LiderUser
              name="Erhan Güler" number="330"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="100"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="3030"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="29"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="330"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="40"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="550"></LiderUser>
          <LiderUser
              name="Erhan Güler" number="330"></LiderUser>
          </ul>
      </div>
      <div class="course-card-footer">
        <button type="button" class="btn btn-secondary btn-sm uk-first-column">
          Daha Fazla <i class="uil-arrow-right"></i>
        </button>
      </div>
    </div>
  </div></template>

<script>
import LiderUser from "@/view/components/index/LiderUser";
export default {
  name: "LiderTable",
  components:{
    LiderUser
  }
}
</script>

<style scoped>

</style>