<template>
  <div id="Duver" class="uk-card-default uk-card-hover rounded mt-3">
    <ul class="uk-child-width-expand uk-tab" uk-switcher="animation: uk-animation-fade">
      <li><a href="#"><i class="uil-coffee"></i> {{ $t("sideNav.Wall") }}</a></li>
      <li><a href="#"><i class="uil-clock-nine"></i> Hareketlerim</a></li>
    </ul>
    <ul class="uk-switcher mywall">
      <!-- tab 1 -->
      <li>
        <div class="py-3 px-4">
          <DuverUser name="Ebru Sayan" description="Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  necessitatibus..." sub-title="bir yazı paylaştı."></DuverUser>
          <DuverUser name="Ebru Sayan" description="Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  necessitatibus..." sub-title="bir yazı paylaştı."></DuverUser>
          <DuverUser name="Ebru Sayan" description="Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  necessitatibus..." sub-title="bir yazı paylaştı."></DuverUser>
          <DuverUser name="Ebru Sayan" description="Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  necessitatibus..." sub-title="bir yazı paylaştı."></DuverUser>
          <DuverUser name="Ebru Sayan" description="Lorem ipsum dolor, sit amet consectetur adipisicing elit
                  necessitatibus..." sub-title="bir yazı paylaştı."></DuverUser>


        </div>
      </li>

      <!-- tab 2 -->
      <li>
        <div class="py-3 px-4">

          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-user"></i> Ahmet Harmancı <span>seni düelloya
                                                        davet etti.<br><i>(20.09.2020 14:53:25)</i></span></h6>
            </div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-check"></i> Bankacılık Kanunu <span>eğitimini
                                                        başarıyla tamamladın. <br><i>(19.09.2020 18:01:22)</i></span>
              </h6>
            </div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-plus"></i> E-posta Yazışma Kuralları
                <span>eğitimi atandı. <br><i>(19.09.2020 18:01:22)</i></span></h6>
            </div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-check"></i> İş Sağlığı ve Güvenliği
                <span>eğitimini başarıyla tamamladın. <br><i>(15.09.2020
                                                            12:32:18)</i></span></h6>
            </div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-plus"></i> E-posta Yazışma Kuralları
                <span>eğitimi atandı. <br><i>(19.09.2020 18:01:22)</i></span></h6>
            </div>
          </div>
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand">
              <h6><i class="icon-feather-check"></i> İş Sağlığı ve Güvenliği
                <span>eğitimini başarıyla tamamladın. <br><i>(15.09.2020
                                                            12:32:18)</i></span></h6>
            </div>
          </div>
          <div class="course-card-footer">
            <button type="button" class="btn btn-secondary btn-sm uk-first-column">
              Daha Fazla <i class="uil-arrow-right"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>

</template>

<script>
import DuverUser from "@/view/components/index/DuverUser";
export default {
  name: "Duver",
  components:{
    DuverUser

  }

}
</script>

<style scoped>

</style>