<template>
  <div id="CardInformation">
    <div class="course-card uk-card-hover text-center foruser" :class="{'uk-card-primary': isActive} ">
      <div class="course-card-body">
        <i :class="className"></i>
        <h3> {{ title }}</h3>
        <h4 > {{ subtitle}}</h4>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "CardInformation",
  props:{
    title: {
      required: true,
    },
    className: {
      required: true,
    },
    subtitle: {
      required: true,
    },
    isActive: {
      required: false,
      default: false,
    }
  },
}
</script>

