<template>
  <div id="DuverUser" class="uk-grid-small" uk-grid>
    <div class="uk-width-expand">
      <a href="">
        <h6><i class="icon-feather-user"></i> {{name}}<span>{{subTitle}}</span></h6>
        <p>{{description}}</p>
      </a>
    </div>
  </div>


</template>

<script>
export default {
  name: "DuverUser",
  props:{
    name: {
      required: true,
    },
    subTitle: {
      required: true,
    },
    description: {
      required: true,
    },

  }
}
</script>

